<template>
  <li class="nav-item nav-search">
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon icon="SearchIcon" size="21" />
    </a>

    <!-- Input -->
    <div class="search-input" :class="{ open: showSearchBar }">
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Search Cases"
        autofocus
        autocomplete="off"
        @keyup.enter="submitSearch"
        @blur="showSearchBar = false"
      />
      <!-- <div
        class="search-input-close"
        @click="
          showSearchBar = false
          resetsearchQuery()
        "
      >
        <feather-icon icon="XIcon" />
      </div>

      < Suggestions List 
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="overflow-hidden search-list search-list-main scroll-area"
        :class="{ show: searchQuery }"
        tagname="ul"
      >
        <li
          v-for="(suggestion_list, grp_name, grp_index) in filteredData"
          :key="grp_index"
          class="suggestions-groups-list"
        >
          <! Group Header 
          <p class="suggestion-group-title">
            <span>
              {{ title(grp_name) }}
            </span>
          </p>

           Suggestion List of each group 
          <ul>
            <li
              v-for="(suggestion, index) in suggestion_list"
              :key="index"
              class="cursor-pointer suggestion-group-suggestion"
              :class="{
                'suggestion-current-selected':
                  currentSelected === `${grp_index}.${index}`
              }"
              @mouseenter="currentSelected = `${grp_index}.${index}`"
              @mousedown.prevent="suggestionSelected(grp_name, suggestion)"
            >
              <b-link v-if="grp_name === 'pages'" class="p-0">
                <feather-icon :icon="suggestion.icon" class="mr-75" />
                <span class="align-middle">{{ suggestion.title }}</span>
              </b-link>
              <template v-else-if="grp_name === 'files'">
                <div class="d-flex align-items-center">
                  <b-img :src="suggestion.icon" class="mr-1" height="32" />
                  <div>
                    <p>{{ suggestion.file_name }}</p>
                    <small>by {{ suggestion.from }}</small>
                  </div>
                  <small class="ml-auto">{{ suggestion.size }}</small>
                </div>
              </template>
              <template v-else-if="grp_name === 'contacts'">
                <div class="d-flex align-items-center">
                  <b-avatar :src="suggestion.img" class="mr-1" size="32" />
                  <div>
                    <p>{{ suggestion.name }}</p>
                    <small>{{ suggestion.email }}</small>
                  </div>
                  <small class="ml-auto">{{ suggestion.time }}</small>
                </div>
              </template>
            </li>

            <li
              v-if="!suggestion_list.length && searchQuery"
              class="suggestion-group-suggestion no-results"
            >
              <p>No Results Found.</p>
            </li>
          </ul>
        </li>
      </vue-perfect-scrollbar>
      -->
    </div>
  </li>
</template>

<script>
import { BFormInput } from "bootstrap-vue"
import { ref } from "@vue/composition-api"
import { title } from "@core/utils/filter"

import searchAndBookmarkData from "../search-and-bookmark-data"

export default {
  components: {
    BFormInput
    // BLink,
    // BImg,
    // BAvatar,
    // VuePerfectScrollbar
  },

  data() {
    return {
      searchQuery: ""
    }
  },

  methods: {
    submitSearch() {
      if (this.$route.name === "search-filings") {
        this.$store.dispatch(
          "notifications/updateSearchQuery",
          this.searchQuery
        )
        this.searchQuery = ""
        this.showSearchBar = false
        return
      }
      this.$router.push({
        name: "search-filings",
        params: { search: this.searchQuery }
      })
      this.showSearchBar = false
      this.searchQuery = ""
    }
  },
  setup() {
    const showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60
    }

    const currentSelected = ref(-1)

    return {
      showSearchBar,
      perfectScrollbarSettings,
      searchAndBookmarkData,
      title,

      currentSelected

      // AutoSuggest
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}

.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
