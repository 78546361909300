export default [
  {
    header: "Dashboard",
  },
  {
    icon: "HomeIcon",
    tagVariant: "light-warning",
    title: "Home",
    route: "home-main", 
  },

];