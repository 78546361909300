<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsCount"
        badge-classes="bg-warning"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="relative dropdown-header d-flex">
        <h4 class="mb-0 mr-auto notification-title">Notifications</h4>
        <b-badge pill variant="light-primary">
          {{ notificationsCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="(notification, index) in notifications"
        :key="index"
        @click="viewNotification(notification.id)"
      >
        <b-media v-if="notification.lead_contact" class="relative pr-3">
          <template #aside>
            <!-- <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            /> -->
            <b-avatar size="31" variant="warning">
              <feather-icon size="19" icon="UserPlusIcon" />
            </b-avatar>
          </template>

          <p class="media-heading">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              class="absolute shadow-sm top-1 right-1 btn-icon"
              size="sm"
              @click.stop.prevent="silenceNote(index)"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <span class="font-weight-bolder">
              New Contact from {{ notification.lead_contact.name }}
            </span>
          </p>
          <small>
            <b-col class="mt-2">
              <div class="flex flex-row justify-between justify-center">
                <div class="mr-3 font-bold text-gray-500">case:</div>
                <div class="notification-text">
                  {{ notification.lead_contact.case }}
                </div>
              </div>
              <b-dropdown-divider />
              <div class="flex flex-row justify-between justify-center">
                <div class="mr-3 font-bold text-gray-500">Phone:</div>
                <div class="notification-text">
                  {{ fPhone(notification.lead_contact.phone_number) }}
                </div>
              </div>
              <b-dropdown-divider />
              <div class="flex flex-row justify-between justify-center">
                <div class="mr-3 font-bold text-gray-500">Email:</div>
                <div class="notification-text">
                  {{ notification.lead_contact.email }}
                </div>
              </div>
              <b-dropdown-divider />
              <div class="flex flex-row justify-between justify-center">
                <div class="mr-3 font-bold text-gray-500">Mailing Id:</div>
                <div class="notification-text">
                  {{ notification.lead_contact.mailing_id.id }}
                </div>
              </div>
              <b-dropdown-divider />
              <div class="flex flex-row justify-between justify-center">
                <div class="mr-3 font-bold text-gray-500">Date Mailed:</div>
                <div class="notification-text">
                  {{ notification.lead_contact.mailing_id.export.mailed_date }}
                </div>
              </div>
              <b-dropdown-divider />
              <div class="flex flex-row justify-between justify-center">
                <div class="mr-3 font-bold text-gray-500">Mail Type:</div>
                <div class="notification-text">
                  {{ notification.lead_contact.mailing_id.export.mailing_type }}
                </div>
              </div>
            </b-col>
          </small>
        </b-media>
        <b-media v-else class="relative pr-3">
          <template #aside>
            <!-- <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            /> -->
            <b-avatar size="31" variant="warning">
              <feather-icon size="19" icon="BellIcon" />
            </b-avatar>
          </template>

          <p class="media-heading">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              class="absolute shadow-sm top-1 right-1 btn-icon"
              size="sm"
              @click.stop.prevent="silenceNote(index)"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <span class="font-weight-bolder">
              Case {{ notification.case_number }} has Updated
            </span>
          </p>

          <small class="notification-text"
            >The
            <span class="font-bold text-gray-500">{{
              notification.field
            }}</span>
            has changed from
            <span class="font-bold text-gray-500">{{
              notification.previous_value
            }}</span>
            to
            <span class="font-bold text-gray-500">
              {{ notification.current_value }}</span
            >
            for {{ notification.defendant_name }} {{ index }}.</small
          >
        </b-media>
      </b-link>

      <!-- System Notification Toggler
      <div class="media d-flex align-items-center">
        <h6 class="mb-0 mr-auto font-weight-bolder">
          System Notifications
        </h6>
        <b-form-checkbox :checked="true" switch /> 
      </div>

       System Notifications 
      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click.stop.prevent="silenceNote()"
        >Clear all notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BCol,
  BDropdownDivider,
  //BFormCheckbox
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BCol,
    BDropdownDivider,
    //BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      notificationsCount: "notifications/notificationsCount",
    }),
    notifications: {
      get() {
        let data = this.$store.getters["notifications/notifications"]
          ? this.$store.getters["notifications/notifications"]
          : false;
        return data ? JSON.parse(data) : "";
      },
    },
  },

  methods: {
    fPhone(number) {
      // FORMAT PHONE NUMBER
      var x = number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

      return number;
    },
    viewNotification(id) {
      if (this.$route.name !== "notifications") {
        this.$router.push({
          name: "notifications",
        });
      }
      this.$store.dispatch("notifications/updateSelectedNotification", id);
    },
    silenceNote(index = false) {
      var note = [];
      var id = this.notifications;

      if (typeof index != "boolean") {
        id = this.notifications[index].id;
        this.notifications.splice(index, 1);
      }
      this.$AuthAxios.instance
        .put("/api-access/notifications/silence/", {
          id: id,
        })
        .then(() => {
          this.$store.dispatch(
            "notifications/updateNotifications",
            typeof index == "boolean" ? note : this.notifications
          );
        });
    },
  },
  setup() {
    /* eslint-disable global-require */

    const perfectScrollbarSettings = {
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
</style>
