export default [{
    header: "Mail Center",
  },
  {
    title: "Export Mailing",
    route: "mailing-exports",
    icon: "LogOutIcon",
  },
  {
    title: "Mail Templates",
    tag: "new",
    route: "templates",
    icon: "FileTextIcon",
  },
  {
    title: "History",
    route: "export-history",
    icon: "ClockIcon",
  },
];