<template>
  <b-overlay :show="isDownloading" opacity="0.85" rounded="lg">
    <template #overlay style="max-width: 400px">
      <div
        class="p-1 rounded-lg font-bold text-xl text-gray-700 text-center"
        style="min-width: 300px"
      >
        <b-row>
          <b-spinner small label="Small Spinner" class="mb-2" /> Fetching
          Download, Please Wait ...
        </b-row>
      </div>
    </template>
    <div
      :class="`toastification border-${
        complete ? variant : 'white'
      }  rounded-lg`"
    >
      <b-container class="rounded-lg p-1">
        <b-col cols="12" style="min-width: 325px">
          <b-row class="rounded-lg bg-gray-200 border">
            <b-avatar
              :variant="variant"
              size="1.8rem"
              class="mr-75 flex-shrink-0"
            >
              <feather-icon v-if="downloadFailed" icon="XIcon" size="15" />
              <feather-icon v-else-if="complete" icon="CheckIcon" size="15" />
              <b-spinner v-else small label="Small Spinner" />
            </b-avatar>
            <h5 class="mb-0 font-weight-bolder toastification-title">
              <div v-if="downloadFailed" class="text-danger">
                Download Failed!
              </div>
              <div v-else-if="complete" class="text-success">
                Mailing Generated!
              </div>
              <div v-else class="text-primary">
                Your Mailing is Being Generated ...
              </div>
            </h5>
          </b-row>
          <b-row class="mt-2 text-gray-600">
            <div v-if="downloadFailed">
              <span class="text-xs"
                >Please refresh your browser and try again. If the problem
                persists, contact support. Sorry for the inconvenience.</span
              >
            </div>
            <div v-else>
              <span class="font-bold mr-1 text-sm">FILE :</span>
              <span class="text-xs">{{ fileName }}</span>
            </div>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12" class="text-center">
              <div class="flex flex-row w-full">
                <div
                  :class="`text-gray-600 font-bold ${
                    complete ? 'line-through' : null
                  }`"
                >
                  Est. Time Remaining :
                </div>

                <div
                  v-if="downloadFailed"
                  class="flex flex-row ml-1 text-white"
                >
                  <feather-icon icon="XCircleIcon" size="15" class="mr-1" />
                  done
                </div>
                <div
                  v-else-if="complete"
                  class="flex flex-row ml-1 text-success"
                >
                  <feather-icon
                    v-if="complete"
                    icon="CheckCircleIcon"
                    size="15"
                    class="mr-1"
                  />
                  done
                </div>
                <div v-else class="ml-1">{{ calculatedTime }}</div>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-2">
            <div class="flex flex-row justify-center items-center w-full">
              <div>
                <b-progress
                  v-if="downloadFailed"
                  :value="100"
                  variant="danger"
                  class="progress-bar-primary"
                  style="min-width: 250px"
                />
                <b-progress
                  v-else-if="complete"
                  value="100"
                  variant="success"
                  class="progress-bar-primary"
                  style="min-width: 250px"
                />
                <b-progress
                  v-else
                  animated
                  :value="progress"
                  variant="primary"
                  class="progress-bar-primary"
                  style="min-width: 250px"
                />
              </div>
            </div>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col :cols="12">
                  <div class="flex flex-row justify-center items-center">
                    <b-button
                      v-if="complete"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      style="min-width: 150px"
                      @click="downloadTest()"
                    >
                      <span class="align-middle">Download</span>
                    </b-button>
                    <b-button
                      v-else-if="!downloadFailed"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline"
                      class="text-gray-500 border rounded-lg"
                      style="min-width: 150px"
                    >
                      <span class="align-middle">Download</span>
                    </b-button>
                  </div>
                </b-col>
                <b-col v-if="!complete && !downloadFailed" cols="4">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                    @click="cancelDownload"
                  >
                    <span class="align-middle underline">cancel</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import {
  BOverlay,
  BContainer,
  BCol,
  BAvatar,
  BRow,
  BSpinner,
  BProgress,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BContainer,
    BCol,
    BAvatar,
    BRow,
    BSpinner,
    BProgress,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    bId: {
      type: Number,
      default: 0,
    },
    store: {
      type: Object,
      default: function () {
        return null;
      },
    },
    axios: {
      type: Function,
      default: function () {
        return null;
      },
    },
    bComplete: {
      type: Boolean,
      default: false,
    },
    bTimeRemaining: {
      type: [Number, String],
      default: null,
    },
    bProgress: {
      type: Number,
      default: 1,
    },
    bFileName: {
      type: String,
      default: null,
    },
    bFileId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      downloadFailed: false,
      uploadPercentage: 0,
      isDownloading: false,
      id: this.bId,
      complete: this.bComplete,
      calculatedTime: "calculating...",
      timeRemaining: this.bTimeRemaining,
      progress: this.bProgress,
      fileName: this.bFileName,
      fileId: this.bFileId,
      taskId: this.bTaskId,
      oldProgress: "",
      oldDate: "",
      downloadCanceled: false,
    };
  },
  computed: {
    ...mapGetters({
      refreshDownload: "notifications/refreshDownloadNotification",
    }),
    variant() {
      if (this.downloadFailed) {
        return "danger";
      }
      if (this.complete) {
        return "success";
      }
      return "primary";
    },
  },
  created() {
    this.setTimer();
    if (!this.complete && !this.downloadCanceled) {
      this.getDownload();
    }
    setInterval(
      function () {
        if (!this.complete && !this.downloadCanceled && !this.downloadFailed) {
          this.getDownload();
        }
      }.bind(this),
      3000
    );
  },

  methods: {
    downloadTest() {
      this.downloadExport();

      var link = document.createElement("a");

      link.setAttribute("download", "file");

      link.href = this.fileId;
      document.body.appendChild(link);
      link.click();
      link.remove();

      var increment =
        this.store.getters["notifications/refreshDownloadNotification"] + 1;

      this.store.dispatch("notifications/showDownloadNotification", false);

      this.store.dispatch(
        "notifications/refreshDownloadNotification",
        increment
      );

      this.$emit("close-toast");
    },

    async downloadExport() {
      const l1 = this;

      l1.isDownloading = true;

      await l1.$AuthAxios.instance
        .get(
          "/api-access/exports/download-export/",
          {
            params: {
              id: l1.id,
            },
          },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(await function () {})
        .catch((error) => {
          console.log(error);
        });
    },
    cancelDownload() {
      var l1 = this;

      l1.downloadCanceled = true;

      var increment =
        l1.store.getters["notifications/refreshDownloadNotification"] + 1;

      l1.store.dispatch("notifications/showDownloadNotification", false);

      l1.store.dispatch("notifications/refreshDownloadNotification", increment);

      l1.$emit("close-toast");

      l1.axios
        .post("/api-access/exports/download-cancel/", {
          params: {
            id: l1.id,
          },
        })
        .then(function () {})
        .catch((error) => {
          console.log(error);
        });
    },
    setTimer(interval) {
      var l1 = this;
      function DateOffset(offset) {
        return new Date(+new Date() + offset);
      }
      setInterval(function () {
        if (l1.complete) {
          clearInterval(interval);
        }

        if (l1.timeRemaining != "calculating..." && !l1.complete) {
          var countDownDate;
          if (l1.oldProgress === l1.progress) {
            countDownDate = l1.oldDate;
          } else {
            countDownDate = DateOffset(l1.timeRemaining * 1000);
          }

          l1.oldProgress = l1.progress;

          l1.oldDate = countDownDate;
          // Get today's date and time
          var now = new Date().getTime();

          // Find the distance between now and the count down date
          var distance = countDownDate - now;

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          var remainingTime;
          if (Math.sign(days) < 0) {
            remainingTime = "finalizing ...";
          } else {
            remainingTime =
              days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
          }

          l1.calculatedTime = remainingTime;
        }
      }, 1000);
    },
    getDownload() {
      var l1 = this;

      l1.axios
        .get("/api-access/exports/templates/download-status-id/", {
          params: { id: l1.id },
        })
        .then(function (ele) {
          l1.store.dispatch(
            "notifications/downloadNotificationType",
            ele.data.complete ? "complete" : "active"
          );

          l1.id = ele.data.id;
          l1.complete = ele.data.complete;
          l1.fileId = ele.data.fileId;
          l1.fileName = ele.data.fileName;
          l1.progress = ele.data.progress;
          l1.taskId = ele.data.taskId;
          l1.timeRemaining = ele.data.timeRemaining;
        })
        .catch(() => {
          //l1.downloadCanceled = true;

          l1.downloadFailed = true;

          l1.store.dispatch("notifications/showDownloadNotification", false);

          // l1.$emit("close-toast");

          l1.axios
            .post("/api-access/exports/download-cancel/", {
              params: {
                id: l1.id,
              },
            })
            .then(function () {})
            .catch((error) => {
              console.log(error);
            });
        });
    },

    changeStatus() {
      if (this.complete) {
        this.complete = false;
      } else {
        this.complete = true;
      }
    },
  },
};
</script>
import { BNavbar } from "bootstrap-vue"
<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
