export default [{
    header: "Manage",
  },
  {
    title: "Settings & Info",
    route: "user-settings",
    icon: "UserIcon",

  },
  {
    title: "Account Settings",
    route: "account-settings",
    icon: "SlidersIcon",
    action: "get",
    resource: "accountAdmin",
  },
  //{
  //  title: "Add/Remove Users",
  //  route: "add-remove-users",
  //  icon: "UsersIcon",
  //  meta: {
  //    action: "get",
  //    reference: "accountAdmin",
  //  },
  //},
];