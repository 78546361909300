<template>
  <div></div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent2.vue";
export default {
  props: {
    axios: {
      type: Object,
      default: function () {
        return {};
      },
    },
    mailTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mailExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      complete: true,
      timeRemaining: "---",
      progress: 1,
      fieldId: null,
      taskId: null,
      fileName: "12_13_2021__15:33:26__gaGbPeES4rsk...",
    };
  },

  created() {
    var l1 = this;
    l1.getDownloads();
  },
  methods: {
    getDownloads() {
      var l1 = this;

      l1.$AuthAxios.instance
        .get("/api-access/exports/templates/download-status/", {})
        .then(function (res) {
          l1.newDownload = false;
          l1.downloadCount = res.data.length;
          res.data.forEach((ele) => {
            l1.$store.dispatch(
              "notifications/downloadNotificationType",
              ele.complete ? "complete" : "active"
            );

            l1.toggleDownload(
              ele.id,
              ele.complete,
              ele.fileId,
              ele.fileName,
              ele.progress,
              ele.taskId,
              ele.timeRemaining,
              l1.$store,
              l1.$AuthAxios.instance
            );
          });
        })
        .catch((error) => {
          console.log(error);
        });
      return null;
    },
    toggleDownload(
      id,
      complete,
      fileId,
      fileName,
      progress,
      taskId,
      timeRemaining,
      store,
      axios
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            bId: id,
            store: store,
            axios: axios,
            bComplete: complete,
            bTimeRemaining: timeRemaining,
            bProgress: progress,
            bFileName: fileName,
            bFileId: fileId,

            bTaskId: taskId,
          },
        },
        {
          timeout: false,
        }
      );
    },
  },
};
</script>
