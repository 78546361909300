export default [{
    header: "Management",
  },
  {
    title: "Notifications",
    route: "notifications",
    icon: "BellIcon",
  },

  {
    title: "Compliance",
    route: "compliance",
    icon: "ShieldIcon",
    action: "get",
    resource: "accountAdmin",
  },
  
];