export default [{
    header: "Tracking & Stats",
  },
  {
    title: "Case Tracker",
    tag: "new",
    tagVariant: "light-success",
    route: "case-tracker",
    icon: "MapPinIcon",
  },
  {
    title: "Suitinfo.net",
    tag: "NEW",
    tagVariant: "light-success",
    route: "mail-stats",
    icon: "MailIcon",
  },
  {
    title: "Phone Calls",
    tag: "NEW",
    tagVariant: "light-success",
    route: "calls",
    icon: "PhoneIcon",
  },
];