<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!--<download-monitor
      v-if="showDownload"
      :key="refreshDownload"
      :axios="$AuthAxios"
    /> -->
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <clock />
    </div>

    <b-navbar-nav class="ml-auto nav align-items-center">
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->

      <download-monitor
        v-if="showDownload && axiosAvailable"
        :key="refreshDownload"
      />
      <download-button v-if="showDownload && axiosAvailable" />
      <search-bar />
      <!-- <cart-dropdown /> -->
      <notification-dropdown />
      <user-dropdown />
      <div class="text-xs text-gray-300">{{ version }}</div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import Clock from "./components/Clock.vue";
//import Locale from "./components/Locale.vue"
import SearchBar from "./components/SearchBar.vue";
//import DarkToggler from "./components/DarkToggler.vue"
//import CartDropdown from "./components/CartDropdown.vue"
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import { mapGetters } from "vuex";
import DownloadMonitor from "./components/download-monitor/DownloadMonitor";
import DownloadButton from "./components/DownloadButton.vue";

export default {
  components: {
    DownloadButton,
    BLink,
    DownloadMonitor,
    // Navbar Components
    BNavbarNav,
    Clock,
    //Locale,
    SearchBar,
    //DarkToggler,
    //CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      componentKey: 0,
      axios: null,
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapGetters({
      notificationsCount: "notifications/notificationsCount",
      showDownload: "notifications/showDownloadNotification",
      refreshDownload: "notifications/refreshDownloadNotification",
      axiosAvailable: "notifications/axiosAvailable",
    }),
  },
  watch: {
    axiosAvailable(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.setNotificationWatchers();
      }
      console.log(newValue, oldValue);
    },
    notificationsCount() {
      this.componentKey += 1;
    },
  },
  created() {},
  methods: {
    setNotificationWatchers() {
      var l1 = this;
      l1.$GetDownloads(l1.$AuthAxios, l1.$store);
      setInterval(function () {
        l1.$GetDownloads(l1.$AuthAxios, l1.$store);
      }, 300000);
      l1.$GetNotifications(l1.$AuthAxios, l1.$store);
      setInterval(function () {
        l1.$GetNotifications(l1.$AuthAxios, l1.$store);
      }, 3600000);
    },
  },
};
</script>
