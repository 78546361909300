export default [
  {
    header: "Site Admin",
    action: "get",
    resource: "siteOwner",
  },
 
    {
      icon: "SendIcon",
      tagVariant: "light-warning",
      title: "Generate Mailing",
      route: "generate-mailing",
      action: "get",
      resource: "siteOwner",
   
    },
    {
      icon: "SearchIcon",
      tagVariant: "light-warning",
      title: "Claims Action",
      route: "claims-action",
      action: "get",
      resource: "siteOwner",
   
    },
    {
      icon: "ActivityIcon",
      tagVariant: "light-warning",
      title: "Crawler Activity",
      route: "crawler-activity",
      action: "get",
      resource: "siteOwner",
   
    },
    {
      icon: "RadioIcon",
      tagVariant: "light-warning", 
      title: "Subscriptions", 
      route: "county-subscriptions",
      action: "get",
      resource: "siteOwner",
   
    },
    {
      icon: "UploadCloudIcon",
      tagVariant: "light-warning",
      title: "Import Cases", 
      route: "case-upload",
      tag: "!",
      action: "get",
      resource: "siteOwner",
   
    },
    {
      icon: "ClockIcon",
      tagVariant: "light-warning",
      title: "Schedule Crawlers", 
      route: "start-crawler",
      action: "get",
      resource: "siteOwner",
   
    },
  ];