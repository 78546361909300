<template>
  <ul :key="componentKey">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in addValues"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils"
import { provide, ref } from "@vue/composition-api"
import VerticalNavMenuHeader from "../vertical-nav-menu-header"
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue"
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue"
import { mapGetters } from "vuex"
export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      componentKey: 0,
      updatedItems: []
    }
  },
  computed: {
    ...mapGetters({
      trackedCases: "auth/trackedCases",
      notificationsCount: "notifications/notificationsCount"
    }),

    addValues() {

      var i;

      var items = this.items

      for (i = 0; i < items.length; i++) {
      var item = items[i];

       if (item['header'] == 'Site Admin' && !this.$can('post', 'siteOwner')) { 
          items.splice(i, 1);
          i--; //decrement
      }
      // items[i]['title'] --;
      if (item.title === "Case Tracker") {
          items[i]["tag"] = this.trackedCases
          items[i]["tagVariant"] = "success"
        }
        if (item.title === "Notifications") {
          items[i]["tagVariant"] = "warning"

          items[i]["tag"] = `${this.notificationsCount}`
        }
      

     
}
      return items
    }
  },
  watch: {
    trackedCases() {
      this.componentKey += 1
    },
    notificationsCount() {
      this.componentKey += 1
    }
  },

  created() {
    //this.updatedItems = addValues()
  },

  setup() {
    provide("openGroups", ref([]))

    return {
      resolveNavItemComponent
    }
  }
}
</script>
