<template>
  <div class="flex ml-3 font-weight-bolder">
    <div class="font-weight-bold ml">
      <div>
        {{ date }}
      </div>
    </div>

    <div class="ml-1 font-weight-bold">
      <div>{{ time }} {{ amPm }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: "",
      date: "",
      week: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      hours: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ],
      day: "",
      amPm: "",
      timerID: setInterval(this.updateTime, 1000)
    }
  },
  computed: {
    function() {
      return this.updateTime()
    }
  },
  methods: {
    zeroPadding(num, digit) {
      var zero = ""
      for (var i = 0; i < digit; i++) {
        zero += "0"
      }
      return (zero + num).slice(-digit)
    },
    updateTime() {
      var cd = new Date()
      this.amPm = cd.getHours() - 1 < 11 ? "a.m" : "p.m"
      this.time =
        this.hours[cd.getHours() - 1] +
        " : " +
        this.zeroPadding(cd.getMinutes(), 2)
      this.day = this.week[cd.getDay()] + " "
      this.date =
        this.month[cd.getMonth()] +
        ", " +
        this.zeroPadding(cd.getDate(), 2) +
        " " +
        this.zeroPadding(cd.getFullYear(), 4)
    }
  },

  setup() {}
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
