<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="success"
      class="mr-2"
      @click="setAxios"
    >
      <div class="flex flex-row items-center justify-center">
        <feather-icon icon="DownloadIcon" class="text-xs mr-1" />
        <span class="align-middle text-xs"> View Active Downloads </span>
      </div>
    </b-button>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BButton } from "bootstrap-vue";
import { mapGetters } from "vuex";
export default {
  components: { BButton },
  directives: { Ripple },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      refreshDownload: "notifications/refreshDownloadNotification",
    }),
  },
  methods: {
    setAxios() {
      var increment = this.refreshDownload;
      increment = increment + 1;
      this.$store.dispatch(
        "notifications/refreshDownloadNotification",
        increment
      );
    },
  },
};
</script>
